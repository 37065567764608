@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img,
object {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}

* {
  -moz-box-sizing: border-box;
  -webkit-kit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}


button {
  color: #55215e;
  background-color: white;
  border: 2px solid #55215e;
  padding: 15px 32px; 
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  width:248px;
  transition-duration: 0.4s;
  border-radius: 10px;
}

button:hover {
  background-color: #55215e;
  color: #ffc717;
}  
/*  
button:focus  {
  color: #ffc717;

} */



ul.unordered-list {
    margin-top: 10px;
    padding: 0;
   
  }
   
 li.list-item {
    opacity: 4;
    transition: all 0.6s;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 3px 0;
    color: #55215e;
    cursor: pointer;
  }

  @media only screen and (max-width: 992px) {
    ul.unordered-list{
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row-reverse;
              flex-direction: row-reverse;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
    }
    li.list-item {
      padding: 0px 5px;
    }
  }



a{
  text-decoration: none;
  color: #55215e;
}

a:hover {
  text-decoration: none;
  color: #55215e;
  opacity: 0.5;
}

